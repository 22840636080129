var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AssisstantBar'),_c('v-container',{attrs:{"fluid":""}},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"icon":"mdi-account-star","title":"รับรายงานตัวครูผู้ช่วย"}},[_c('v-card',{staticClass:"mb-4 pa-2"},[_c('v-row',[_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","md":"8"}},[_c('v-text-field',{staticClass:"mb-2",attrs:{"append-icon":"mdi-magnify","label":"ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง","single-line":"","hide-details":"","dense":"","filled":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","md":"4"}},[_c('v-btn',{attrs:{"block":"","right":"","depressed":"","color":"info"},nativeOn:{"click":function($event){return _vm.manage_assistant_teacherTotal()}}},[_c('v-icon',[_vm._v("mdi-clipboard-text")]),_vm._v("สรุปจำนวนเรียกรายงานตัว ")],1),_c('v-btn',{attrs:{"block":"","right":"","depressed":"","color":"info"},nativeOn:{"click":function($event){return _vm.manage_assistant_teacherQueryAll()}}},[_c('v-icon',[_vm._v("mdi-clipboard-text")]),_vm._v("แสดงข้อมูลทั้งหมด ")],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"color":"success","loading":_vm.loading,"headers":_vm.headers,"items":_vm.manage_assistant_teachers,"search":_vm.search,"group-by":"mt_id_branch"},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var items = ref.items;
var isOpen = ref.isOpen;
var toggle = ref.toggle;
return [_c('th',{attrs:{"colspan":"22"}},[_c('v-icon',{on:{"click":toggle}},[_vm._v(_vm._s(isOpen ? "mdi-minus" : "mdi-plus")+" ")]),_vm._v(" "+_vm._s(items[0].mt_id_branch + " : " + items[0].name_branch)+" ")],1)]}},{key:"item.mt_admissions_status",fn:function(ref){
var item = ref.item;
return [(item.mt_admissions_status === 'missing')?_c('v-chip',{attrs:{"dark":"","color":"red"}},[_c('v-icon',[_vm._v("mdi-information")]),_vm._v("ไม่มารายงานตัว ")],1):_vm._e(),(item.mt_admissions_status === 'receipt')?_c('v-chip',{attrs:{"dark":"","color":"primary"}},[_c('v-icon',[_vm._v("mdi-information")]),_vm._v("มารายงานตัวปกติ ")],1):_vm._e()]}},{key:"item.mt_type_personnel",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[(item.mt_type_personnel === 'local_dev')?_c('v-chip',{attrs:{"dark":"","color":"waring"}},[_c('v-icon',[_vm._v("mdi-information")]),_vm._v("ครูพัฒนาท้องถิ่น ")],1):_vm._e(),(item.mt_type_personnel === 'transfer_position')?_c('v-chip',{attrs:{"dark":"","color":"warning"}},[_c('v-icon',[_vm._v("mdi-information")]),_vm._v("ครูโอนย้าย ")],1):_c('v-chip',{attrs:{"dark":"","color":"green"}},[_c('v-icon',[_vm._v("mdi-information")]),_vm._v("รับรายงานตัว ปกติ ")],1)],1)]}},{key:"item.mt_admissions_file",fn:function(ref){
var item = ref.item;
return [(item.mt_admissions_file)?_c('v-chip',{attrs:{"dark":"","color":"info","href":'/HRvecfiles/' + item.mt_admissions_file,"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-printer")])],1):_vm._e()]}},{key:"item.mt_status",fn:function(ref){
var item = ref.item;
return [(item.mt_status === 'disclaim')?_c('v-chip',{attrs:{"dark":"","color":"red"}},[_c('v-icon',{attrs:{"dark":"","color":"black"}},[_vm._v("mdi-lightbulb-outline")])],1):_c('v-chip',{attrs:{"dark":"","color":"green"}},[_c('v-icon',[_vm._v("mdi-lightbulb-on-outline")])],1)]}},{key:"item.mt_examtype",fn:function(ref){
var item = ref.item;
return [(item.mt_examtype === 'select')?_c('span',{attrs:{"dark":"","color":"purple"}},[_vm._v(" คัดเลือก ")]):_c('span',{attrs:{"dark":"","color":"indigo"}},[_vm._v(" สรรหา ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.mt_collegeCC === 'CC')?_c('div',[_c('v-chip',{attrs:{"color":"red","dark":""}},[_c('v-icon',[_vm._v("mdi-information-outline")]),_vm._v(" แจ้งรายงานข้อมูลผิดพลาด")],1)],1):_c('div',[(item.mt_admissions_status === 'send')?_c('v-icon',{attrs:{"large":"","color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.manage_assistant_teacherEdit(item.mt_id)}}},[_vm._v(" mdi-email ")]):(item.mt_admissions_status === 'missing')?_c('v-icon',{attrs:{"large":"","color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.manage_assistant_teacherEdit(item.mt_id)}}},[_vm._v(" mdi-account-off ")]):(item.mt_admissions_status === 'receipt')?_c('v-icon',{attrs:{"large":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.manage_assistant_teacherEdit(item.mt_id)}}},[_vm._v(" mdi-account-check ")]):_c('v-icon',{attrs:{"dark":"","color":"warning"}},[_vm._v("mdi-cube-send")])],1)]}}])},[_c('v-alert',{attrs:{"slot":"no-results","value":true,"color":"error","icon":"mdi-alert"},slot:"no-results"},[_vm._v("ไม่พบผลลัพธ์ \""+_vm._s(_vm.search)+"\" ที่คุณกำลังค้นหา.")])],1)],1),_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"60%"},model:{value:(_vm.editmanage_assistant_teacherdialog),callback:function ($$v) {_vm.editmanage_assistant_teacherdialog=$$v},expression:"editmanage_assistant_teacherdialog"}},[_c('v-card',{staticClass:"mx-auto pa-6"},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"color":"warning","icon":"mdi-email-open","title":'รายรายงานตัว ครูผู้ช่วยรอบที่ : ' +
                _vm.periodassteachs.periodAssTeachTimes +
                ' / ' +
                _vm.periodassteachs.periodAssTeachYear}}),_c('v-card-text',[_c('v-form',{ref:"editmanage_assistant_teacherform",attrs:{"lazy-validation":""}},[_c('v-container',{attrs:{"grid-list-md":""}},[_c('v-list',{attrs:{"two-line":""}},[_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"indigo"}},[_vm._v(" mdi-account ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.editmanage_assistant_teacher.mt_id_card))]),_c('v-list-item-subtitle',[_vm._v("เลขบัตรประชาชน")])],1),_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"indigo"}},[_vm._v(" mdi-account ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.editmanage_assistant_teacher.mt_title_s + _vm.editmanage_assistant_teacher.mt_frist_name + " " + _vm.editmanage_assistant_teacher.mt_last_name))]),_c('v-list-item-subtitle',[_vm._v("ชื่อ-นามสกุล")])],1),_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"indigo"}},[_vm._v(" mdi-school ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.editmanage_assistant_teacher.mt_id_branch))]),_c('v-list-item-subtitle',[_vm._v("สาขาวิชา : "+_vm._s(_vm.editmanage_assistant_teacher.name_branch))])],1)],1),_c('v-list-item',[_c('v-list-item-action'),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.editmanage_assistant_teacher.mt_tel_p))]),_c('v-list-item-subtitle',[_vm._v("Tel.")])],1),_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-message-text")])],1)],1),_c('v-divider',{attrs:{"inset":""}}),(
                      _vm.editmanage_assistant_teacher.mt_admissions_status ===
                        'missing' ||
                        _vm.editmanage_assistant_teacher.mt_admissions_status ===
                          'receipt'
                    )?_c('v-list-item',[_c('v-list-item-action'),_c('v-list-item-content',[_c('v-list-item-title',[(
                            _vm.editmanage_assistant_teacher.mt_admissions_status ===
                              'missing'
                          )?_c('h2',{staticClass:"red--text"},[_vm._v(" ไม่มารายงานตัว ")]):_vm._e(),(
                            _vm.editmanage_assistant_teacher.mt_admissions_status ===
                              'receipt'
                          )?_c('h2',{staticClass:"green--text"},[_vm._v(" มารายงานตัว ")]):_vm._e()]),_c('v-list-item-subtitle',[_vm._v("สถานะการรับรายงานตัว")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('v-chip',{staticClass:"pa-2",attrs:{"href":'/HRvecfiles/' +
                              _vm.editmanage_assistant_teacher.mt_admissions_file,"target":"_blank","color":"info"}},[_c('v-icon',{staticClass:"mr-5"},[_vm._v("mdi-printer")]),_c('h2',[_vm._v("หนังสือนำส่ง")])],1)],1),_c('v-list-item-subtitle',[_vm._v("หนังสือแจ้งรับรายงานตัว")])],1),_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-message-text")])],1)],1):_vm._e(),_c('v-divider',{attrs:{"inset":""}}),(
                      _vm.editmanage_assistant_teacher.mt_admissions_status ===
                        'missing' ||
                        _vm.editmanage_assistant_teacher.mt_admissions_status ===
                          'receipt'
                    )?_c('v-list-item',[_c('v-list-item-action'),_c('v-list-item-content',[_c('v-list-item-title',[_c('h2',{staticClass:"red--text"},[_vm._v(" ขอยกเลิกการรายงานข้อมูลเนื่องจากรายงานผิดพลาด "),_c('br'),_c('v-btn',{attrs:{"outlined":"","color":"red"},on:{"click":function($event){return _vm.manage_assistant_teacherCC()}}},[_vm._v("ขอยกเลิกการรายงาน")])],1)]),_c('v-list-item-subtitle',[_vm._v("สถานะการรับรายงานตัว")])],1)],1):_vm._e()],1),(
                    _vm.editmanage_assistant_teacher.mt_type_personnel ===
                      'transfer_position'
                  )?_c('div',[_c('v-alert',{attrs:{"type":"warning"}},[_c('div',[_c('u',{staticClass:"font-weight-bold red--text"},[_vm._v("ครูโอนย้าย")]),_vm._v(" วิทยาลัยรอให้ต้นสังกัดครูโอนย้าย อนุมัติให้โอนย้าย โดยสามารถติดต่อประสานงานกับครูผู้โอนย้าย เพื่อขอทราบกำหนดการมารายงานตัว ")])])],1):_vm._e(),(
                    _vm.editmanage_assistant_teacher.mt_admissions_status ===
                      'send'
                  )?_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"md4":""}},[_c('v-autocomplete',{attrs:{"items":_vm.mt_admissions_status_select,"item-text":"text","item-value":"value","outlined":"","label":"สถานะการรับรายงานตัว","required":"","rules":[function (v) { return !!v || ''; }]},model:{value:(
                        _vm.editmanage_assistant_teacher.mt_admissions_statusSelect
                      ),callback:function ($$v) {_vm.$set(_vm.editmanage_assistant_teacher, "mt_admissions_statusSelect", $$v)},expression:"\n                        editmanage_assistant_teacher.mt_admissions_statusSelect\n                      "}})],1),_c('v-flex',{attrs:{"md8":""}},[_c('v-file-input',{attrs:{"accept":".pdf","name":"mt_admissions_file","color":"deep-purple accent-4","counter":"","label":"หนังสือนำส่ง .pdf","placeholder":"หนังสือแจ้งรับรายงานตัว","outlined":"","show-size":1000,"rules":_vm.rules},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                      var index = ref.index;
                      var text = ref.text;
return [(index < 2)?_c('v-chip',{attrs:{"color":"deep-purple accent-4","dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(text)+" ")]):(index === 2)?_c('span',{staticClass:"text-overline grey--text text--darken-3 mx-2"},[_vm._v(" +"+_vm._s(_vm.files.length - 2)+" File(s) ")]):_vm._e()]}}],null,false,4244939259),model:{value:(_vm.mt_admissions_file),callback:function ($$v) {_vm.mt_admissions_file=$$v},expression:"mt_admissions_file"}})],1),_c('v-flex',{attrs:{"md6":""}},[_c('v-autocomplete',{attrs:{"items":_vm.subject_types,"item-text":"subject_typeName","item-value":"subject_typeID","outlined":"","label":"ประเภทวิชา"},on:{"change":function($event){return _vm.rate_work_course_stdQuery()}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                      var item = ref.item;
return [_c('div',{staticClass:"pa-2"},[_c('div',{staticClass:"divTab50"},[_vm._v(" "+_vm._s(item.subject_typeID)+" : "+_vm._s(item.subject_typeName)+" ")])])]}}],null,false,183698144),model:{value:(_vm.editmanage_assistant_teacher.mt_subject),callback:function ($$v) {_vm.$set(_vm.editmanage_assistant_teacher, "mt_subject", $$v)},expression:"editmanage_assistant_teacher.mt_subject"}})],1),_c('v-flex',{attrs:{"md6":""}},[_c('v-autocomplete',{attrs:{"items":_vm.rate_work_course_stds,"item-text":"branch_name_th","item-value":"id_course_branch","outlined":"","label":"สาขาวิชา"},on:{"change":function($event){return _vm.rate_work_course_stdSubQuery()}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                      var item = ref.item;
return [_c('div',{staticClass:"pa-2"},[_c('div',{staticClass:"divTab50"},[_vm._v(" "+_vm._s(item.id_course_branch)+" : "+_vm._s(item.branch_name_th)+" ")])])]}}],null,false,1095358817),model:{value:(_vm.editmanage_assistant_teacher.mt_branch),callback:function ($$v) {_vm.$set(_vm.editmanage_assistant_teacher, "mt_branch", $$v)},expression:"editmanage_assistant_teacher.mt_branch"}})],1),_c('v-flex',{attrs:{"md6":""}},[_c('v-autocomplete',{attrs:{"outlined":"","label":"สาขางาน","items":_vm.rate_work_course_stdsSub,"item-text":"branch_sub_name_th","item-value":"id_course_branch_sub"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                      var item = ref.item;
return [_c('div',{staticClass:"pa-2"},[_c('div',{staticClass:"divTab50"},[_vm._v(" "+_vm._s(item.id_course_branch_sub)+" : "+_vm._s(item.branch_sub_name_th)+" ")])])]}}],null,false,1375611361),model:{value:(_vm.editmanage_assistant_teacher.mt_branch_sub),callback:function ($$v) {_vm.$set(_vm.editmanage_assistant_teacher, "mt_branch_sub", $$v)},expression:"editmanage_assistant_teacher.mt_branch_sub"}})],1)],1):_vm._e()],1),_c('small',[_vm._v("* จำเป็น")])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"large":"","outlined":"","rounded":""},on:{"click":function($event){$event.stopPropagation();_vm.editmanage_assistant_teacherdialog = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-close")]),_vm._v("ยกเลิก ")],1),_c('v-btn',{attrs:{"large":"","color":"warning","rounded":""},on:{"click":function($event){$event.stopPropagation();return _vm.editmanage_assistant_teacherSubmit()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-pencil")]),_vm._v(" บันทึก ")],1)],1)],1)],1)],1),_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"40%"},model:{value:(_vm.editmanage_assistant_teacherCCdialog),callback:function ($$v) {_vm.editmanage_assistant_teacherCCdialog=$$v},expression:"editmanage_assistant_teacherCCdialog"}},[_c('v-card',{staticClass:"mx-auto pa-6"},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"color":"red","icon":"mdi-email-open","title":'รายรายงานตัว ครูผู้ช่วยรอบที่ : ' +
                _vm.periodassteachs.periodAssTeachTimes +
                ' / ' +
                _vm.periodassteachs.periodAssTeachYear}}),_c('v-card-text',[_c('v-form',{ref:"editmanage_assistant_teacherCCdialogform",attrs:{"lazy-validation":""}},[_c('v-container',{attrs:{"grid-list-md":""}},[_c('v-list',{attrs:{"two-line":""}},[_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"indigo"}},[_vm._v(" mdi-account ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.editmanage_assistant_teacher.mt_id_card))]),_c('v-list-item-subtitle',[_vm._v("เลขบัตรประชาชน")])],1),_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"indigo"}},[_vm._v(" mdi-account ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.editmanage_assistant_teacher.mt_title_s + _vm.editmanage_assistant_teacher.mt_frist_name + " " + _vm.editmanage_assistant_teacher.mt_last_name))]),_c('v-list-item-subtitle',[_vm._v("ชื่อ-นามสกุล")])],1),_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"indigo"}},[_vm._v(" mdi-school ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.editmanage_assistant_teacher.mt_id_branch))]),_c('v-list-item-subtitle',[_vm._v("สาขาวิชา : "+_vm._s(_vm.editmanage_assistant_teacher.name_branch))])],1)],1),_c('v-list-item',[_c('v-list-item-action'),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.editmanage_assistant_teacher.mt_tel_p))]),_c('v-list-item-subtitle',[_vm._v("Tel.")])],1),_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-message-text")])],1)],1),_c('v-divider',{attrs:{"inset":""}}),(
                      _vm.editmanage_assistant_teacher.mt_admissions_status ===
                        'missing' ||
                        _vm.editmanage_assistant_teacher.mt_admissions_status ===
                          'receipt'
                    )?_c('v-list-item',[_c('v-list-item-action'),_c('v-list-item-content',[_c('v-list-item-title',[(
                            _vm.editmanage_assistant_teacher.mt_admissions_status ===
                              'missing'
                          )?_c('h2',{staticClass:"red--text"},[_vm._v(" ไม่มารายงานตัว ")]):_vm._e(),(
                            _vm.editmanage_assistant_teacher.mt_admissions_status ===
                              'receipt'
                          )?_c('h2',{staticClass:"green--text"},[_vm._v(" มารายงานตัว ")]):_vm._e()]),_c('v-list-item-subtitle',[_vm._v("สถานะการรับรายงานตัว")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('v-chip',{staticClass:"pa-2",attrs:{"href":'/HRvecfiles/' +
                              _vm.editmanage_assistant_teacher.mt_admissions_file,"target":"_blank","color":"info"}},[_c('v-icon',{staticClass:"mr-5"},[_vm._v("mdi-printer")]),_c('h2',[_vm._v("หนังสือนำส่ง")])],1)],1),_c('v-list-item-subtitle',[_vm._v("หนังสือแจ้งรับรายงานตัว")])],1),_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-message-text")])],1)],1):_vm._e(),_c('v-divider',{attrs:{"inset":""}}),(
                      _vm.editmanage_assistant_teacher.mt_admissions_status ===
                        'missing' ||
                        _vm.editmanage_assistant_teacher.mt_admissions_status ===
                          'receipt'
                    )?_c('v-list-item',[_c('v-list-item-action')],1):_vm._e()],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","large":"","rounded":""},on:{"click":function($event){$event.stopPropagation();_vm.editmanage_assistant_teacherCCdialog = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-close")]),_vm._v("ยกเลิก ")],1),_c('v-btn',{attrs:{"large":"","color":"red","rounded":""},on:{"click":function($event){$event.stopPropagation();return _vm.editmanage_assistant_teacherCCdialogSubmit()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-clipboard-alert")]),_vm._v(" แจ้งยกเลิก ")],1)],1)],1)],1)],1),_c('v-layout',[_c('v-dialog',{attrs:{"persistent":"","max-width":"80%"},model:{value:(_vm.manage_assistant_teacherCountDialog),callback:function ($$v) {_vm.manage_assistant_teacherCountDialog=$$v},expression:"manage_assistant_teacherCountDialog"}},[_c('v-card',{staticClass:"mx-auto pa-5"},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"color":"green","icon":"mdi-content-paste","title":"ข้อมูลสรุปครูผู้ช่วย รายงานตัว"}}),_c('v-card-text',[_c('v-card',[_c('v-data-table',{attrs:{"color":"success","loading":_vm.loading,"headers":_vm.headersCount,"items":_vm.manage_assistant_teacherCount},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
                    var item = ref.item;
return [_c('v-icon',{attrs:{"color":"info"},on:{"click":function($event){$event.stopPropagation();return _vm.matBranchQuery(
                        item.mt_times,
                        item.mt_years,
                        item.mt_id_branch
                      )}}},[_vm._v(" mdi-account-search ")])]}}])})],1)],1),_c('v-row',[_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","md":"12"}},[_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","large":"","color":"grey"},on:{"click":function($event){$event.stopPropagation();_vm.manage_assistant_teacherCountDialog = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-close ")])],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }